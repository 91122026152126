import PrivatePolicy from "./utils/PrivatePolicy";
import './../../modules/media/svg/theme/js/jquery.svgInject.js';

class Site {
	public static MOBILE_WIDTH	= 750;

	public static PrivatePolicy = PrivatePolicy;

	private static initMenus(): void {
		let $header = $('#header');
		let $mainmenu = $('.mainmenu');
		let $topmenu = $('.topmenu');

		let $button = $('.mainmenu__button');

		$button.click((e) => {
			$header.toggleClass('header--opened', !$header.hasClass('header--opened'));
			$topmenu.toggleClass('topmenu--opened', !$topmenu.hasClass('topmenu--opened'));
			$mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'));
		});

		let update = () => {
			let vw = $(window).width();

			$mainmenu.toggleClass('mainmenu--mobile', vw <= this.MOBILE_WIDTH);
		}

		$(window).on('resize', update);
		update();
	}

	public static discover($parent?: JQuery): void {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]')).fancybox({
			titlePosition: 'over'
		});

		PrivatePolicy.discover($parent);

		(<any> $('.svg-inject')).svgInject();
	}

	public static init(): void {
		(<any> window).Site = Site;

		PrivatePolicy.init();

		$(() => {
			this.initMenus();
			this.discover();
		});
	}
}

Site.init();
